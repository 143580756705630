<template>
  <div class="pageWrapper">
    <h2 class="headerTitle">Static Data</h2>
    <div>
      <table class="standardTable">
        <fragment v-if="staticDataList.length > 0">
          <tr class="standardTableHeader">
            <th width="200px">Key</th>
            <th>Information</th>
            <th>Enabled</th>
            <th>Remove</th>
          </tr>
          <StaticDataItem
            :staticDataItem="staticDataItem"
            v-for="staticDataItem in staticDataList"
            :key="staticDataItem.id"
            :getStaticData="getStaticData"
          />
        </fragment>
        <tr class="tableRow">
          <td @click="addStaticData" class="addNewStaticData" colspan="4">
            <div>
              <ion-icon name="add-circle-outline"></ion-icon>
              <p>Add new static data</p>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MainButton from '@/layout/MainButton'
import StaticDataItem from '../../components/settings/staticData/StaticDataItem.vue'
import AddStaticDataPopup from '../../components/settings/staticData/AddStaticDataPopup.vue'

export default {
  components: {
    MainButton,
    StaticDataItem,
    AddStaticDataPopup
  }, data() {
    return {
      staticDataList: [],
      staticKey: undefined,
      staticInformation: undefined,
    }
  },
  created() {
    this.getStaticData()
  },
  methods: {
    addStaticData() {
      this.$store.commit('SET_POPUP', { component: AddStaticDataPopup, properties: { getStaticData: this.getStaticData } })
    },
    getStaticData() {
      axios.post('settings/getStaticData', {}).then((response) => {
        this.staticDataList = response.data
      })
    },
  }
}
</script>

<style scoped lang="scss">
.addNewStaticData {
  text-align: center;
  cursor: pointer;
}
.addNewStaticData:hover {
  -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.25);
}
.addNewStaticData div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addNewStaticData div p {
  margin-left: 10px;
}
.addNewStaticData div ion-icon {
  font-size: 15pt;
  color: $accent;
}

.addStaticValue {
  background: white;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.addStaticValue input {
  border: none;
  padding: 5px;
  border-collapse: collapse;
  border-bottom: 1px solid rgb(204, 204, 204);
  color: #333;
  margin: 0 10px;
}
.headerTitle {
  font-family: 'Avenir';
  font-size: 20pt;
  margin-bottom: 10px;
}
.staticDataInfo {
  font-family: 'Avenir';
  font-size: 10pt;
  margin-bottom: 10px;
}
</style>