<template>
  <tr class="tableRow">
    <td width="20%">
      {{ staticDataItem.staticKey }}
    </td>
    <td width="20%">
      <textarea
        type="text"
        v-model="staticDataItem.information"
        spellcheck="false"
      />
    </td>
    <td width="20%">
      <md-switch v-model="switchData" />
    </td>
    <td width="20%">
      <MainButton
        v-if="
          oldData != JSON.stringify(this.staticDataItem) ||
          switchData != oldSwitchData
        "
        :clickFunction="updateData"
        title="Update"
        styleClass="primary"
        :buttonStyle="{ margin: '0 10px' }"
      />
      <MainButton
        :clickFunction="
          () => {
            removeStaticData(staticDataItem)
          }
        "
        title="Delete"
        styleClass="delete"
        :buttonStyle="{ margin: '0 10px' }"
      />
    </td>
  </tr>
</template>

<script>
import MainButton from '@/layout/MainButton'
import axios from 'axios'

export default {
  props: ['staticDataItem', 'getStaticData'],
  components: {
    MainButton,
  },
  data() {
    return {
      oldData: JSON.stringify(this.staticDataItem),
      switchData: (this.staticDataItem.status == 1) ? true : false,
      oldSwitchData: (this.staticDataItem.status == 1) ? true : false,
    }
  },
  methods: {
    removeStaticData(data) {
      var r = confirm(`Are you sure you want to delete: ${data.staticKey}?`);
      if (r == true) {
        axios.post('settings/removeStaticData', { id: data.id }).then((response) => {
          this.getStaticData()
        })
      }
    },
    updateData() {
      this.staticDataItem.status = (this.switchData == true) ? 1 : 0

      axios.post('settings/updateStaticData', { staticData: this.staticDataItem }).then((response) => {
        this.oldSwitchData = this.switchData
        this.oldData = JSON.stringify(this.staticDataItem)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.tableRow {
  border: 3px solid red;
}

.tableRow:hover {
  background: #fafafa;
}

.tableRow .md-switch {
  margin: 15px;
}

.tableRow td input {
  border: none;
  border-collapse: collapse;
  border-bottom: 1px solid rgb(204, 204, 204);
  color: #333;
  margin: 0 10px;
  transition: 0.2s ease-in-out;
}
</style>