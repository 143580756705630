<template>
  <div class="popupBox">
    <div class="popContent">
      <div class="popTopRow">
        <ion-icon
          style="cursor: pointer"
          @click="closePopup"
          name="close-outline"
        ></ion-icon>
      </div>
      <div class="popMiddleRow">
        <p>Add static data</p>

        <div class="popMiddleInput">
          <p>Write a key to identify the static data</p>
          <input type="text" placeholder="Unique key" v-slugify v-model="staticKey" />
          <ion-icon name="key-outline"></ion-icon>
        </div>
        <div class="popMiddleInput">
          <p>Write data that can be fetched from the static data</p>
          <input
            type="text"
            placeholder="Information"
            v-model="staticInformation"
          />
          <ion-icon name="brush-outline"></ion-icon>
        </div>
      </div>

      <div class="popBottomRow">
        <MainButton
          :clickFunction="closePopup"
          title="Cancel"
          styleClass="secondary"
          :buttonStyle="buttonStyle"
        />
        <MainButton
          :clickFunction="saveChanges"
          title="Add"
          styleClass="primary"
          :buttonStyle="buttonStyle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '@/layout/MainButton'
import axios from 'axios'

export default {
  components: { MainButton },
  props: ['getStaticData'],
  data() {
    return {
      staticKey: '',
      staticInformation: '',
      buttonStyle: {
        padding: '10px 25px 8px 25px',
        fontSize: '10pt',
      },
    }
  },
  methods: {
    saveChanges() {
      axios.post('settings/addStaticData', { staticKey: this.staticKey.trim(), staticInformation: this.staticInformation }).then((response) => {
        this.getStaticData()
        this.$store.commit('CLEAR_POPUP')
      })
    },
    closePopup() {
      this.$store.commit('CLEAR_POPUP')
    },
  },
}
</script>

<style scoped src="@/assets/css/popup.css"></style>
<style scoped lang="scss">


.popContent {
  width: 100%;
  position: relative;
  display: inline;
}

/* Pop top row */

.popTopRow {
  width: 100%;
  text-align: right;
  font-size: 24pt;
}

/* Pop middle row */

.popMiddleRow {
  position: relative;
  margin: 10px;
  margin-top: 0px;
}
.popMiddleRow p {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 13pt;
}

.popMiddleInput {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.popMiddleInput input {
  border: none;
  border-bottom: 1px solid rgb(99, 99, 99);
  padding: 3px;
  position: relative;
  width: 100%;
  margin-top: 5px;
  transition: 0.2s ease-in-out;
}

.popMiddleInput input:focus {
  border-bottom: 1px solid $accent;
}

.popMiddleInput p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  font-size: 9pt;
}

.popMiddleInput ion-icon {
  position: absolute;
  right: 0px;
  bottom: 50%;
  transform: translateY(100%);
}

/* Pop bottom row */

.popBottomRow {
  position: relative;
  margin: 10px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
</style>